import { ParsedUrlQueryInput } from 'querystring';
import { NextApiRequest, NextPageContext } from 'next';
import requestIP from 'request-ip';
import { marked } from 'marked';
import xss from 'xss';
import platform from 'platform';
import { parseCookies } from 'nookies';
// eslint-disable-next-line import/no-duplicates
import { isValid, parseISO } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { de } from 'date-fns/locale';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';

import { StaticImageData } from 'next/image';
import category10000 from '@public/assets/icons/category-10000.png';
import category11000 from '@public/assets/icons/category-11000.png';
import category12000 from '@public/assets/icons/category-12000.png';
import category13000 from '@public/assets/icons/category-13000.png';
import { getIPLocation } from 'services/ipStack';
import { CookiesName } from './cookies';

export const genId = () => {
  return Math.random()
    .toString(32)
    .replace(/^.{0,2}/, '');
};

interface GetClientIP {
  req: NextApiRequest | NextPageContext['req'];
}

export const getClientIp = (params: GetClientIP) => {
  const { req } = params;
  if (!req) return '';

  const xffHeaderNames = [
    'x-forwarded-for',
    'x-forwarded',
    'forwarded-for',
    'forwarded',
  ];

  const xffIP = xffHeaderNames
    .map(name => {
      const value = req.headers[name];

      if (!value) return '';

      if (Array.isArray(value)) return value.shift();

      return value.split(',').shift();
    })
    .find(Boolean);

  const ip = req.headers['X-Forwarded-For'] || requestIP.getClientIp(req);

  return xffIP || String(ip);
};

interface IPLocationCache {
  [clientIp: string]: {
    location: string;
    timestamp: number;
  };
}

const ipLocationCache: IPLocationCache = {};

const CACHE_EXPIRATION_MINUTES = 20;

interface GetClientLocation {
  clientIp: string;
}

export const getClientLocation = async (params: GetClientLocation) => {
  const { clientIp } = params;

  if (!clientIp) return '';

  if (
    process.env.NODE_ENV === 'development' &&
    process.env.LATITUDE_LONGITUDE
  ) {
    return process.env.LATITUDE_LONGITUDE;
  }

  const cachedLocation = ipLocationCache[clientIp];

  if (cachedLocation) {
    const now = new Date();
    const cacheTime = new Date(cachedLocation.timestamp);

    const minutesSinceCache = Math.floor(
      (now.getTime() - cacheTime.getTime()) / (1000 * 60),
    );

    if (minutesSinceCache <= CACHE_EXPIRATION_MINUTES) {
      return cachedLocation.location;
    }
  }

  const location = await getIPLocation(clientIp);

  if (!location) return '';

  const latitudeLongitude = `${location.latitude},${location.longitude}`;

  ipLocationCache[clientIp] = {
    location: latitudeLongitude,
    timestamp: new Date().getTime(),
  };

  return latitudeLongitude;
};

interface FormatNumberParams {
  value: number;
  minDecimalDigits?: number;
  maxDecimalDigits?: number;
}

export const formatNumber = (params: FormatNumberParams) => {
  const { value, maxDecimalDigits = 2, minDecimalDigits } = params;

  return new Intl.NumberFormat('DE-de', {
    minimumFractionDigits: minDecimalDigits,
    maximumFractionDigits: maxDecimalDigits,
  }).format(value);
};

interface FormatCurrencyParams {
  value: number;
  minDecimalDigits?: number;
  maxDecimalDigits?: number;
}

export const formatCurrency = (params: FormatCurrencyParams) => {
  const { value, maxDecimalDigits = 2, minDecimalDigits } = params;

  return new Intl.NumberFormat('DE-de', {
    minimumFractionDigits: minDecimalDigits,
    maximumFractionDigits: maxDecimalDigits,
    style: 'currency',
    currency: 'EUR',
  }).format(value);
};

export const formatFloatToInteger = (value: number | string) => {
  const multipliedValue = (Number(value) * 100).toFixed();

  return parseInt(multipliedValue, 10);
};

export const isServer = () => typeof window === 'undefined';

interface SendEmailParams {
  to?: string;
  subject?: string;
  body: string;
}

export const sendEmail = (params: SendEmailParams): void => {
  const { to = ' ', subject = '', body } = params;

  const anchor = document.createElement('a');

  anchor.href = `mailto:${encodeURIComponent(to)}?subject=${encodeURIComponent(
    subject,
  )}&body=${encodeURIComponent(body)}`;

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

export const multiplyBy100 = (value: number | string) => {
  const multipliedValue = (Number(value) * 100).toFixed(2);

  return Number(multipliedValue);
};

export const divideBy100 = (value: number | string) => {
  const dividedValue = (Number(value) / 100).toFixed(4);

  return Number(dividedValue);
};

interface FindStoreCategoryParams {
  categoryId: string;
  categories: Category[];
}

export const findStoreCategoryById = (
  params: FindStoreCategoryParams,
): Category | null => {
  const { categoryId, categories } = params;

  return categories.reduce((acc, category) => {
    if (acc) {
      return acc;
    }
    if (category.id === categoryId) {
      return category;
    }
    return findStoreCategoryById({ categories: category.children, categoryId });
  }, null as Category | null);
};

export const getCategoryIcon = (parentCategoryId: string) => {
  const ICONS: Record<string, StaticImageData> = {
    '10000': category10000,
    '11000': category11000,
    '12000': category12000,
    '13000': category13000,
  } as const;

  return ICONS[parentCategoryId] || '';
};

export const parseMarkdownToHtml = (markdown: string) => {
  if (!markdown) return '';

  return xss(marked.parse(markdown, { mangle: false, headerIds: false }));
};

export const isMacOS = () => {
  if (typeof window === 'undefined') return false;

  const { os = '' } = platform;

  const _isMacOS = /^(mac\s?)?os\sx/i.test(os.toString());

  return _isMacOS;
};

export const isZmyleCookie = (
  value?: string | Record<string, any>,
): value is ZmyleCookie => {
  if (!value || (typeof value === 'object' && !('consents' in value))) {
    return false;
  }

  try {
    if (typeof value !== 'string') return false;

    const parsedCookie = JSON.parse(value);

    return Boolean('consents' in parsedCookie);
  } catch {
    return false;
  }
};

export const getZmyleCookie = (
  params?: Parameters<typeof parseCookies>[0],
): ZmyleCookie => {
  const cookies = parseCookies(params);

  const zmyleCookie = cookies[CookiesName.zmyleCookie];

  return isZmyleCookie(zmyleCookie)
    ? JSON.parse(zmyleCookie)
    : { consents: { marketing: [], media: [] } };
};

export const getDomainFromUrl = (website: string) => {
  const regexUrlPrefix = /^(?:https?:\/\/)?(?:www\.)?/i;

  return website?.replace(regexUrlPrefix, '');
};

interface ParseDateFromServerOptions {
  timezoneId?: string;
}

export const parseDateFromServer = (
  date?: string | null,
  options?: ParseDateFromServerOptions,
) => {
  const { timezoneId = 'Europe/Berlin' } = options || {};

  if (!date) return null;

  const parsedDate = parseISO(date);

  const zonedDateTime = utcToZonedTime(parsedDate, timezoneId, {
    locale: de,
  });

  return zonedDateTime;
};

interface FormatDateToAppOptions {
  timezoneId?: string;
}

export const formatDateToApp = (
  date?: Date | string | null,
  options?: FormatDateToAppOptions,
) => {
  const { timezoneId = 'Europe/Berlin' } = options || {};

  if (!date) return '';

  const dateToFormat =
    typeof date === 'string' ? parseDateFromServer(date) : date;

  if (!isValid(dateToFormat) || !dateToFormat) return '';

  const zonedDateTime = utcToZonedTime(dateToFormat, timezoneId, {
    locale: de,
  });

  return formatInTimeZone(zonedDateTime, timezoneId, 'dd/MM/yyyy', {
    locale: de,
  });
};

export const buildQueryParams = (params: Record<string, unknown>) => {
  const queryParams = Object.entries(params).reduce((acc, current, index) => {
    const prefix = index > 0 ? '&' : '';
    const [key, value] = current;

    const encodedValue = encodeURI(String(value));

    return `${acc}${prefix}${key}=${encodedValue}`;
  }, '');

  return queryParams;
};

interface ResolvePathParams {
  pathname: string;
  query?: ParsedUrlQueryInput;
}

export const resolvePath = (route: ResolvePathParams) => {
  const { pathname, query = {} } = route;

  const urlParams = pathname.match(/\[(.*?)\]/g) || [];
  let newUrlPath = pathname;
  const remainingQuery: Record<string, any> = { ...query };

  urlParams.forEach(param => {
    const paramName = param.slice(1, -1);
    const paramValue = query[paramName];

    newUrlPath = newUrlPath.replace(param, String(paramValue));
    delete remainingQuery[paramName];
  });

  const queryString = buildQueryParams(remainingQuery);

  if (queryString) newUrlPath += `?${queryString}`;

  return newUrlPath;
};

interface DoesRouteMatchParams {
  signature: string;
  route: string | null;
}

export const doesRouteMatch = (params: DoesRouteMatchParams) => {
  const { signature, route } = params;

  const signatureParams = signature.match(/\[(.*?)\]/g) || [];
  let newSignaturePath = signature;

  signatureParams.forEach(param => {
    newSignaturePath = newSignaturePath.replace(param, `([^/]+)`);
  });

  const regex = new RegExp(`^${newSignaturePath}$`);
  return regex.test(String(route));
};
